import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/midiTest'

import Socket from 'lib/socket'
import Toastr from 'toastr'

import Debug from 'debug'
const debug = Debug('pandore:views:gamemaster:midiTest')

const MidiTestView = Marionette.View.extend({
	template: template,
	className: 'container midiTest',

	ui: {
		noteNumberInput: '#noteNumber',
	},

	events: {
		'submit form': 'handleFormSubmit'
	},

	initialize() {
		debug('Initializing MidiTestView:',this.options)
		this.requestMidiNoteList()
	},

	serializeData() {
		return {
			notes: this.noteList,
		}
	},

	async requestMidiNoteList() {
		try {
			const list = await Socket.emit('midi:note:list')
			debug('Received note list:',list)
			this.noteList = list
			if (this.isRendered())
				this.render()
		} catch (err) {
			debug('Error listing midi notes:',err)
			Toastr.error(`Error listing midi notes: ${err}`)
		}
	},

	async handleFormSubmit(event) {
		event.preventDefault()
		debug('Form submitted.')
		const noteValueStr = this.ui.noteNumberInput.val().trim()

		if (!noteValueStr) {
			debug('No note specified, doing nothing.')
			return
		}

		const noteValue = parseInt(noteValueStr,10)
		debug('note value:',noteValue)

		try {
			await Socket.emit('request:midi:note',noteValue)
			Toastr.success(`Note played: ${noteValue}`)
		} catch (err) {
			debug('Error playing midi note:',err)
			Toastr.error(`Error playing note: ${err}`)
		}
	},
})

export default MidiTestView
