import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<tr>\n		<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"module") : depth0), depth0))
    + "</td>\n		<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</td>\n		<td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</td>\n	</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>MIDI Test</h1>\n\n<form>\n	<div class=\"form-group row\">\n		<label for=\"noteNumber\" class=\"col-sm-2 col-form-label\">Note Number</label>\n		<div class=\"col-sm-3\">\n			<input id=\"noteNumber\" class=\"form-control\" type=\"number\" placeholder=\"Note number\"></input>\n		</div>\n		<button class=\"btn btn-primary\">Submit</button>\n	</div>\n</form>\n\n<h2>Note List</h2>\n<table class=\"noteList table table-striped\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":21,"column":9}}})) != null ? stack1 : "")
    + "</table>\n";
},"useData":true});